import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Main from "../Routes/Main";
import Privacy from "../Routes/Privacy";
import Fail from "../Routes/Fail";

export default () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Main></Main>} />
        <Route path="/privacy" element={<Privacy></Privacy>} />
        <Route path="/*" element={<Fail></Fail>} />
        {/* <Route path="/*" element={<Fail></Fail>}></Route> */}
      </Routes>
    </HashRouter>
  );
};
