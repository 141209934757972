import React from "react";
import Container from "../Components/Container";
import Cursor from "../Components/Cursor";
import Header from "../Components/Header";
import Intro from "../Components/Intro";
import PrivacyPolicy from "../Components/PrivacyPolicy";

const Main = () => {
  return (
    <Container>
      <Cursor></Cursor>
      <Header></Header>
      <PrivacyPolicy></PrivacyPolicy>
    </Container>
  );
};

export default Main;
