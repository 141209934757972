const LinkArray = {
  download: [
    {
      url: "https://apps.apple.com/us/app/mojitto-daily-emoji-diary/id1631717082",
      label: "Apple Appstore",
    },
    {
      url: "https://play.google.com/store/apps/details?id=co.teamblender.mojitto&pli=1",
      label: "Google Playstore",
    },
  ],
  channel: [
    { url: "https://www.instagram.com/mojitto_official/", label: "Instagram" },
    { url: "https://teamblender.co", label: "Team Blender" },
  ],
  notice: [
    {
      url: "https://radial-maple-d9c.notion.site/2e900c910fdf432f8585f0ed49ddcf02",
      label: "(구)모지또 서비스 종료 안내",
    },
  ],
};

export default LinkArray;
