import React from "react";
import styled from "styled-components";

const Box = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.7;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
`;

const PrivacyPolicy = () => {
  return (
    <Box className="dD">Mojitto doesn’t collect personal information.</Box>
  );
};

export default PrivacyPolicy;
